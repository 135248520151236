<template>
    <div class="row vert-offset-top-half " >
        <div class="row justify-content-center" v-show="selected_comp == false">
            <div class="col s12 ">
                <div class="col s12 l10 offset-l1">
                    <div class="card col grey lighten-4 s12 vert-offset-top-half z-depth-3"
                    >
                        <div v-if="canShowContactForm && !hasProvidedMobile && !hasSelectedType" class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">

                            <contact-form></contact-form>
                        </div>
                        <div v-if="found_organisation && !hasSelectedType && (!canShowContactForm || (canShowContactForm && hasProvidedMobile))"
                             class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2"
                        >
                            <div class="col s12  vert-offset-bottom-1 text-align-center">
                                <h5>What would you like to share with us?</h5>
                            </div>
                            <div v-for="(item, index) in list_types"
                                 :key="index" class="col s12 m4">
                                <p>
                                    <label @click="selectType(item)">
                                        <input type="radio" :value="item.key" name="type" />
                                        <span class="bold black-text">{{ item.name }}</span>
                                    </label>
                                </p>
                            </div>
                            <div class="row">
                                <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                                    <div class="col s6 m4 l3">
                                        <button
                                                class="btn white grey-text text-darken-2 font-10 col s12"
                                                @click.prevent="backToHome"
                                        >
                                            <i class="material-icons left">chevron_left</i>
                                            Back
                                        </button>
                                    </div>
                                    <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                                        <button
                                                :disabled="!hasSelectedTypeView"
                                                class="btn grey darken-2 font-10 col s12"
                                                @click.prevent="setSelectedType"
                                        >
                                            Continue
                                            <i class="material-icons right">chevron_right</i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="found_organisation && !isCategorySelected && hasSelectedType && (!canShowContactForm || (canShowContactForm && hasProvidedMobile))" class="card-content col s12">
                            <div class="row col s12 no-padding">
                                <div class="row col s12 no-padding">
                             <div class="col s12  vert-offset-bottom-1 text-align-center">
                                <h5>What do you wish to report?</h5>
                             </div>
                             <div class="col s12 ">
                                <div class="col s12 l8 offset-l2 text-align-center">
                                    <div class="col s12 m6"
                                         v-for="(item, index) in list_categories"
                                         :key="index"
                                    >
                                        <button
                                                class="col s12 btn grey darken-2 vert-offset-top-half center font-10"
                                                @click.prevent="selectCategory(item)"
                                        >
                                            {{item.name}}
                                        </button>
                                    </div>
                                </div>
                              </div>
                                </div>
                            </div>


                        </div>

                        <branch v-if="isBranchSelectedCategory && !hasSelectedSite"></branch>
                        <departments v-if="isBranchSelectedCategory && hasDepartments && !isDepartmentSelected"></departments>
                        <options v-if="showOptionsComponent && !isOptionsSelected"></options>
                        <report-type v-if="isOptionsSelected && !show_camera && !show_summary"></report-type>
                        <camera v-if="isOptionsSelected && hasCamera && show_camera"></camera>
                        <summary-component v-if="showSummaryComponent"></summary-component>
                        <other v-if="isOtherSelectedCategory && !hasSelectedLocation && !isOptionsSelected"></other>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import {mapState} from "vuex"
    import Branch from "@/components/Branch_site.vue"
    import Departments from "@/components/DepartmentsComponent.vue"
    import Options from "@/components/Optionslist_component.vue"
    import ReportType from "@/components/TakePicture.vue"
    import Camera from "@/components/CameraComponent.vue"
    import SummaryComponent from "@/components/SummaryComponent.vue"
    import Other from "@/components/OtherComponent.vue"
    import ContactForm from "@/components/ContactFormComponent.vue"
    import http from "../http-common"
/*    import InputComponent from "./materiallize/InputComponent";*/

    export default{
        name: "organisation-view-select",
        components: {
            /*InputComponent,*/
            "Branch":Branch,
            "Departments": Departments,
            "Options": Options,
            'ReportType':ReportType,
            'Camera':Camera,
            'SummaryComponent':SummaryComponent,
            "Other": Other,
            "ContactForm": ContactForm,
        },
        data()
        {
            return {
                selected_type_view:null,
                phone:null,
                component:"",
                disable:false,
                stage:[],
                selected_option: false,
                organisation:{
                    searched:false,
                    found:false,
                    selected:null,
                    sites:{
                        department:null,
                    },

                },
                other:{
                    external_location:true,
                },
                selected_comp:false,
                selected_component:"",
                showBranch: false,
                showOther: false,
                organisationname: this.$route.params.alias,
                errors:[],
                load_nextcomponent:false,
                compatibility: false,
                page_loaded: false,
                OSName: "",
                refcode:"",
            }
        },
        methods: {
            backToHome: function()
            {
                this.$store.dispatch('SET_SELECTED_ACTION_STATUS', false);
                this.$store.dispatch('SET_SELECTED_ACTION_REPORT', false);
            },
            selectCategory: function(_item)
            {
                this.$store.dispatch('SET_SELECTED_CATEGORY', _item);
            },
            selectType: function(item)
            {
                this.selected_type_view = item;
            },
            setSelectedType: function()
            {
                this.$store.dispatch("SET_SELECTED_TYPE", this.selected_type_view);
            },


            loadOther: function()
            {   this.selected_comp = true;
                //this.$store.dispatch('SET_SELECTED_OFFENSES', this.list_offenses);
                this.selected_component = 'Other';
            },
            loadBranch: function()
            {
                this.selected_comp = true;
                //this.$store.dispatch('SET_SELECTED_OFFENSES', this.list_offenses);
                this.selected_component = 'Branch';
            },

            checkReportStatus: function() {
                this.organisation.searched = true;
                this.$store.dispatch("START_PROCESSING");
                return new Promise(resolve => {
                    http({
                        url: "/tipoff/report/status",
                        data: {
                            organisation: this.selected_organisation.key,
                            report: this.refcode,
                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof resp.data.data !== 'undefined' && resp.data.data !== null)
                            {
                                this.refcode = "";
                                _arrMessages.push("Report Code #"+resp.data.data.code);
                                _arrMessages.push("Status: "+resp.data.data.status.data.name);
                                _arrMessages.push("Last Update: "+resp.data.data.updated_at);
                                this.$store.dispatch("GET_MESSAGES", _arrMessages);
                            }

                            console.log("Status",resp.data.data);
                            resolve(resp);
                        })
                        .catch(response => {
                            /*console.log("ErrResponse: " + JSON.stringify(response));*/
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof  response.data !== 'undefined' && response.data !== null && typeof  response.data.messages !== 'undefined' && response.data.messages !== null)
                            {
                                _arrMessages = response.data.messages;
                            }
                            else if(typeof  response.message !== 'undefined' && response.message !== null)
                            {
                                _arrMessages.push(response.message);

                            }
                            else
                            {
                                _arrMessages.push("An error occurred, please try again later.");
                            }
                            this.$store.dispatch("GET_MESSAGES", _arrMessages);

                            resolve(response);
                        });
                });
            },

        },
        watch: {
            'selected_category': function (_value)
            {

                console.log("Selected Category"+ JSON.stringify( _value));
            }
        },
        computed: {
            hasSelectedType: function () {
                return typeof this.selected_type !== 'undefined' && this.selected_type !== null;
            },
             hasSelectedTypeView: function () {
                return typeof this.selected_type_view !== 'undefined' && this.selected_type_view !== null;
            },


            showSummaryComponent: function()
            {
                return (this.isOptionsSelected && this.isReportTypeSelected && this.isMessageProvided && !this.show_camera && this.show_summary);
            },
            isReportTypeSelected: function()
            {
                return (typeof this.selected_type !== "undefined" && this.selected_type !== null);
            },
            isMessageProvided: function()
            {
                return (typeof this.provided_message !== "undefined" && this.provided_message !== null);
            },
            hasRefCode: function ()
            {
                return (typeof this.refcode !== "undefined" && this.refcode !== null);
            },
            isValidRefCode: function ()
            {
                return this.hasRefCode && this.refcode.length == 8;
            },
            hasDepartments: function()
            {
                return (this.found_organisation && this.hasSelectedSite && typeof this.selected_site.departments !== "undefined" && this.selected_site.departments !== null &&  typeof this.selected_site.departments.data !== "undefined" && this.selected_site.departments.data && this.selected_site.departments.data.length > 0);
            },
            isDepartmentSelected: function()
            {
                return (this.hasDepartments && typeof this.selected_department !== "undefined" && this.selected_department !== null );
            },
            hasAddress: function()
            {
                return (typeof this.provided_address !== "undefined" && this.provided_address !== null);
            },
            hasSelectedSite: function()
            {
                return (typeof this.selected_site !== "undefined" && this.selected_site !== null);
            },
            hasSelectedCity: function()
            {
                return (typeof this.selected_city !== "undefined" && this.selected_city !== null);
            },
            hasSelectedDistrict: function()
            {
                return (typeof this.selected_district !== "undefined" && this.selected_district !== null);
            },
            hasSelectedLocation: function()
            {
                return (typeof this.selected_location !== "undefined" && this.selected_location !== null);
            },
            isCategorySelected: function()
            {
                return (typeof this.selected_category !== "undefined" && this.selected_category !== null &&
                    typeof this.selected_category.alias !== "undefined" && this.selected_category.alias !== null
                );
            },
            isOptionsSelected: function()
            {
                return (typeof this.selected_options !== "undefined" && this.selected_options !== null && this.selected_options.length > 0);
            },
            isBranchSelectedCategory()
            {
                return (this.isCategorySelected && this.selected_category.alias === "branch-site");
            },
            isOtherSelectedCategory()
            {
                return (this.isCategorySelected && this.selected_category.alias === "other");
            },
            found_organisation: function(){
                return typeof this.selected_organisation !== "undefined" && this.selected_organisation !== null;

            },
            found_organisation_sites: function () {
               return this.found_organisation !== null && this.selected_organisation.sites.data.length<1;
            },
            load_component:function () {
                return this.load_nextcomponent == true;
            },
            organisationHasLogo: function()
            {
                return (this.found_organisation && typeof this.organisation.selected.logo !== "undefined" && this.selected_organisation.logo !== null);
            },
            showOptionsComponent: function()
            {
                return (this.isBranchSelectedCategory && this.hasSelectedSite && this.hasDepartments && this.isDepartmentSelected)
                    || (this.isBranchSelectedCategory && this.hasSelectedSite && !this.hasDepartments)
                    || (this.isOtherSelectedCategory && this.hasSelectedLocation && this.hasSelectedDistrict && this.hasSelectedCity && this.hasAddress);
            },

            hasContactForm: function()
            {
                return (this.found_organisation && typeof this.contact_form !== "undefined" && this.contact_form !== null);
            },
            canShowContactForm: function()
            {
                return (this.hasContactForm && typeof this.contact_form.active !== "undefined" && this.contact_form.active !== null && this.contact_form.active === true)
            },
            hasCamera: function()
            {
                if(typeof window.navigator === "undefined" || window.navigator === null || typeof window.navigator.mediaDevices === "undefined" || window.navigator.mediaDevices === null)
                {
                    return false;
                }

                if(typeof window.navigator.mediaDevices.getUserMedia === "undefined" || window.navigator.mediaDevices.getUserMedia === null)
                {
                    return false;
                }

                return true;
            },
            hasProvidedMobile: function()
            {
                return (typeof this.provided_mobile !== "undefined" && this.provided_mobile !== null);
            },
            ...mapState({
                selected_organisation: state =>  state.selected_organisation,
                selected_category: state =>  state.selected_category,
               selected_site: state =>  state.selected_site,
               selected_department: state =>  state.selected_department,
               selected_options: state =>  state.selected_options,
               selected_location: state =>  state.selected_location,
               selected_district: state => state.selected_district,
               selected_city: state => state.selected_city,
               selected_ward: state => state.selected_ward,
               selected_type: state =>  state.selected_type,
               provided_message: state =>  state.provided_message,
               provided_address: state =>  state.provided_address,
               show_camera: state =>  state.show_camera,
               show_summary: state =>  state.show_summary,
               list_categories: state => state.list_categories,
                list_types: state => state.list_types,
                contact_form: state => state.contact_form,
                provided_mobile: state =>  state.provided_mobile,
            }),
        },

        created() {

        },
        mounted()
        {


        }
    }
</script>
<style>
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
</style>