<template>
    <div class="row vert-offset-top-half " >
        <div class="row justify-content-center" v-if="selected_action_status">
            <div class="col s12 ">
                <div class="col s12 l10 offset-l1">
                    <div class="card grey lighten-4 col s12 vert-offset-top-half z-depth-3"
                    >
                        <div v-if="found_organisation" class="card-content col s12">


                            <div class="row col s12 no-padding  ">
                                <div class="col s12  vert-offset-top-1 text-align-center">
                                    <h5>Check Report Status </h5>
                                </div>
                                <div class="col s12 vert-offset-top-1 ">

                                    <nav class="vert-offset-bottom-1">
                                        <div class="nav-wrapper white">
                                            <form @submit.prevent="checkReportStatus">
                                                <div class="input-field">
                                                    <input id="search" type="search" v-model="refcode" required autocomplete="off" placeholder="Type your Ref Code here">
                                                    <label class="label-icon" for="search"><i class="material-icons black-text">search</i></label>
                                                    <i class="material-icons black-text search-close-btn" @click="clearSearch">close</i>
                                                </div>
                                            </form>
                                        </div>

                                    </nav>
                                </div>
                            </div>
                            <div class="col s12 vert-offset-top-1 border-top-1 grey-border border-lighten-3 padding-top-1">
                                <div class="col s6 m4 l3">
                                    <button

                                            class="btn white grey-text text-darken-2 submitbtn vert-offset-top-half font-10"
                                            @click="returnTo"
                                    >
                                        <i class="material-icons left">chevron_left</i>
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import {mapState} from "vuex"
    import http from "../http-common"


    export default{
        name: "CheckStatus",
        components: {

        },
        data()
        {
            return {
                phone:null,
                component:"",
                disable:false,
                stage:[],
                selected_option: false,
                organisation:{
                    searched:false,
                    found:false,
                    selected:null,
                    sites:{
                        department:null,
                    },

                },
                other:{
                    external_location:true,
                },
                selected_comp:false,
                selected_component:"",
                organisationname: this.$route.params.alias,
                errors:[],
                OSName: "",
                refcode:"",
            }
        },
        methods: {
            clearSearch: function()
            {
                this.refcode = null;
            },
            returnTo: function ()
            {
                this.$store.dispatch('SET_SELECTED_ACTION_STATUS', false);
            },

            checkReportStatus: function() {
                if(!this.isValidRefCode)
                {
                    this.$store.dispatch("GET_MESSAGES", ["Invalid Reference Code."]);
                    return false;
                }
                this.organisation.searched = true;
                this.$store.dispatch("START_PROCESSING");
                return new Promise(resolve => {
                    http({
                        url: "/tipoff/report/status",
                        data: {
                            organisation: this.selected_organisation.key,
                            report: this.refcode,
                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof resp.data.data !== 'undefined' && resp.data.data !== null)
                            {
                                this.refcode = "";
                                _arrMessages.push("Report Code #"+resp.data.data.code);
                                _arrMessages.push("Status: "+resp.data.data.status.data.name);
                                _arrMessages.push("Last Update: "+resp.data.data.updated_at);
                                this.$store.dispatch("GET_MESSAGES", _arrMessages);
                            }

                            console.log("Status",resp.data.data);
                            resolve(resp);
                        })
                        .catch(response => {
                            /*console.log("ErrResponse: " + JSON.stringify(response));*/
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof  response.data !== 'undefined' && response.data !== null && typeof  response.data.messages !== 'undefined' && response.data.messages !== null)
                            {
                                _arrMessages = response.data.messages;
                            }
                            else if(typeof  response.message !== 'undefined' && response.message !== null)
                            {
                                _arrMessages.push(response.message);

                            }
                            else
                            {
                                _arrMessages.push("An error occurred, please try again later.");
                            }
                            this.$store.dispatch("GET_MESSAGES", _arrMessages);

                            resolve(response);
                        });
                });
            },

        },
        watch: {

        },
        computed: {
            organisationHasLogo: function()
            {
                return (this.found_organisation && typeof this.selected_organisation.logo !== "undefined" && this.selected_organisation.logo !== null);
            },
            hasRefCode: function ()
            {
                return (typeof this.refcode !== "undefined" && this.refcode !== null);
            },
            isValidRefCode: function ()
            {
                return this.hasRefCode && this.refcode.length === 8;
            },
            found_organisation: function(){
                return typeof this.selected_organisation !== "undefined" && this.selected_organisation !== null;

            },

            load_component:function () {
                return this.load_nextcomponent == true;
            },

            ...mapState({
                selected_organisation: state =>  state.selected_organisation,
                selected_category: state =>  state.selected_category,
               selected_site: state =>  state.selected_site,
               selected_action_status: state => state.selected_action_status,
            }),
        },

        created() {
        },
        mounted()
        {


        }
    }
</script>
<style>
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
</style>