<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">

        <div class="col s12  vert-offset-bottom-1 text-align-center">
            <h5>Please provide specifics about the issue</h5>
        </div>

        <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half">
            <div class="col s12 m8 offset-m2">
                <div v-for="(item, index) in list_options"
                     :key="index"
                     class="col s12"
                >
                    <p>
                        <label>
                            <input @click="toggleSelectedOffence(item)"
                                   type="checkbox"
                            />
                            <span  class="bold black-text">{{ item.name }}</span>
                        </label>
                    </p>
                </div>
                <div class="col s12 text-align-center">
                    <button
                            @click="getOptions"
                            class="btn  grey darken-2"
                            v-if="downloaded && !hasListOptions"
                    >
                        Refresh List
                    </button>

                </div>

            </div>
            <div  class="row col s12 vert-offset-top-1">
                <input-component v-model="messages" type="text" label="Click here to add additional information"></input-component>
            </div>

        </div>
        <div class="row">
            <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                <div class="col s6 m4 l3">
                    <button
                            class="btn white grey-text text-darken-2 font-10 col s12"
                            @click.prevent="clearPreviousData"
                    >
                        <i class="material-icons left">chevron_left</i>
                        Back
                    </button>
                </div>
                <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                    <button
                            :disabled="!canProceed"
                            class="col s12 btn grey darken-2 font-10"
                            @click.prevent="saveOptions"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>


    </div>
</template>
<script>

    /*import TakePicture from "@/components/TakePicture.vue"*/
    import {mapState} from "vuex"
    import http from "../http-common"
    import InputComponent from "./materiallize/InputComponent";

    export default{
        components: {
            InputComponent,
            /*'TakePicture':TakePicture*/
        },
        data()
        {
            return {
                messages:"",
                organisation:[],
                component:"",
                results: [],
                list_options:null,
                downloaded:false,
                selected_option: false,
                selected_offences:[],
                selected_comp:false,
                selected_component:"",



            }
        },
        methods: {
            clearPreviousData: function()
            {
                /*SITE*/
                this.$store.dispatch('SET_SELECTED_DEPARTMENT', null);
                this.$store.dispatch('SET_SELECTED_SITE', null);

                /*OTHER*/
                this.$store.dispatch("SET_PROVIDED_LONGITUDE", null);
                this.$store.dispatch("SET_PROVIDED_LATITUDE", null);
                this.$store.dispatch("SET_PROVIDED_ADDRESS", null);
                this.$store.dispatch("SET_SELECTED_WARD", null);
                this.$store.dispatch("SET_SELECTED_CITY", null);
                this.$store.dispatch("SET_SELECTED_DISTRICT", null);
                this.$store.dispatch("SET_SELECTED_LOCATION", null);
            },
            getOptions: function ()
            {
                this.downloaded= false;
                this.$store.dispatch("START_PROCESSING");
                return new Promise(resolve => {
                    http({
                        url: "/tipoff/option/organisation/list",
                        data: {
                            organisation: this.selected_organisation.id,
                            location: ((this.hasSelectedLocation)?this.selected_location.key:null),
                            category: ((this.hasSelectedCategory)?this.selected_category.key:null),
                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {
                            this.downloaded = true;
                            this.$store.dispatch("STOP_PROCESSING");
                            if(typeof resp.data.data !== 'undefined' && resp.data.data !== null)
                            {
                                this.list_options = resp.data.data;
                            }
                            resolve(resp);
                        })
                        .catch(response => {
                            this.downloaded = true;
                            /*console.log("ErrResponse: " + JSON.stringify(response));*/
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof  response.data !== 'undefined' && response.data !== null && typeof  response.data.messages !== 'undefined' && response.data.messages !== null)
                            {
                                _arrMessages = response.data.messages;
                            }
                            else if(typeof  response.message !== 'undefined' && response.message !== null)
                            {
                                _arrMessages.push(response.message);

                            }
                            else
                            {
                                _arrMessages.push("An error occurred, please try again later.");
                            }
                            this.$store.dispatch("GET_MESSAGES", _arrMessages);

                            resolve(response);
                        });
                });
            },

            saveOptions: function()
            {
                this.setProvidedMessage(this.messages);
                this.$store.dispatch("SET_SELECTED_OPTIONS", this.selected_offences);
            },
            setProvidedMessage: function(_message)
            {
                this.$store.dispatch("SET_PROVIDED_MESSAGE", _message);
            },


            toggleSelectedOffence: function(_key)
            {
                console.log("Toggle Offence: "+_key);
                if(this.selected_offences.indexOf(_key) >= 0)
                {
                    this.selected_offences.splice(this.selected_offences.indexOf(_key), 1);
                }
                else
                {
                    this.selected_offences.push(_key);
                }
            },
        },




        computed: {
            hasSelectedOption: function () {
                return typeof this.selected_offences !== 'undefined' && this.selected_offences !== null && this.selected_offences.length > 0;
            },
            hasListOptions: function () {
                return typeof this.list_options !== 'undefined' && this.list_options !== null && this.list_options.length > 0;
            },
            canProceed: function()
            {
                return this.downloaded && this.hasSelectedOption && (!this.hasMessage || this.isValidMessage );
            },
            hasMessage: function()
            {
                return (typeof this.messages !== "undefined" && this.messages !== null &&  this.messages.length > 0 )
            },

            isValidMessage: function()
            {
                return (typeof this.hasMessage  && this.messages.length <= 200 )
            },
            hasSelectedCategory: function()
            {
                return (typeof this.selected_category !== "undefined" && this.selected_category !== null);
            },
            hasSelectedLocation: function()
            {
                return (typeof this.selected_location !== "undefined" && this.selected_location !== null);
            },
            nextComponentSelected: function()
            {
                return (typeof this.selected_component !== "undefined" && this.selected_component !== null && this.selected_component.length > 0);
            },

            ...mapState({
                selected_organisation: state =>  state.selected_organisation,
                selected_location: state =>  state.selected_location,
                selected_category: state =>  state.selected_category,
                /*list_options: state => state.list_options,*/
            }),

        },
        created() {
            this.getOptions();
        },


        mounted()
        {

        }
    }
</script>
<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }

    .card-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;
    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>