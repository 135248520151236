<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">
        <div v-show="!show_contact_form && !show_summary_details" class="col s12 no-padding">
            <div class="col s12  vert-offset-bottom-1 text-align-center">
                <h5> Do you wish to be contacted?</h5>
            </div>
            <div class="row">
                <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                    <div  class="col s6 text-align-right">
                        <button
                                class="btn grey darken-2 font-10"
                                @click.prevent="showContactForm"
                        >
                            Yes
                        </button>
                    </div>
                    <div class="col s6">
                        <button
                                class="btn grey darken-2 font-10"
                                @click.prevent="showSummaryDetails"
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="show_contact_form && !show_summary_details" class="col s12 no-padding">
            <div class="col s12  vert-offset-bottom-1 text-align-center">
                <h5>What is your mobile number?</h5>
            </div>
            <div class="row col s12">
                <input-component v-model="mobile" label="Enter mobile number here "  type="tel"></input-component>
            </div>
            <div class="row">
                <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                    <div class="col s6 m4 l3">
                        <button class="btn white grey-text text-darken-2 font-10 col s12"
                                @click.prevent="restartView" >
                            Cancel
                        </button>
                    </div>
                    <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                        <button
                                class="btn grey darken-2 font-10 col s12"
                                :disabled="!phoneIsValid"
                                @click.prevent="showSummaryDetails"
                        >
                            Continue
                            <i class="material-icons right">chevron_right</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="show_summary_details" class="col s12 no-padding" >

            <div class="col s12  vert-offset-bottom-1 text-align-center">
                <h5>Summary</h5>
            </div>
            <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half font-12" >

                <div v-if="hasContactNumber" class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                    <div class="col s4 m3 grey-text text-darken-2">
                        Mobile Number
                    </div>
                    <div class="col s8 m9">
                        {{mobile}}
                    </div>
                </div>
                <div class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                    <div class="col s4 m3 grey-text text-darken-2">
                        Contact me?
                    </div>
                    <div class="col s8 m9">
                        <span v-if="contact_me === 1">YES</span>
                        <span v-if="contact_me === 0">NO</span>
                    </div>
                </div>

                <div v-if="hasSelectedSite" class="col s12 no-padding">

                    <div v-if="hasSelectedSite" class="col s12 padding-top-half padding-top-half padding-bottom-half left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                        <div class="col s4 m3 grey-text text-darken-2">
                            Branch
                        </div>
                        <div class="col s8 m9">
                            {{selected_site.name}}
                        </div>
                    </div>
                    <div v-if="hasSelectedDepartment" class="col s12 padding-top-half padding-top-half padding-bottom-half left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                        <div class="col s4 m3  grey-text text-darken-2">
                            Department
                        </div>
                        <div class="col s8 m9">
                            {{selected_department.name}}
                        </div>
                    </div>
                    <div v-if="hasSelectedType" class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                        <div class="col s4 m3 grey-text text-darken-2">
                            Type
                        </div>
                        <div class="col s8 m9">
                            {{selected_type.name}}
                        </div>
                    </div>

                </div>
                <div  class="col s12 no-padding">
                    <div v-if="hasSelectedDistrict"  class="col s12 no-padding">
                        <div v-if="hasSelectedDistrict" class="col s12 padding-top-half padding-top-half padding-bottom-half left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                            <div class="col s4 m3 grey-text text-darken-2">
                                District
                            </div>
                            <div class="col s8 m9">
                                {{selected_district.name}}
                            </div>
                        </div>
                        <div v-if="hasSelectedCity" class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                            <div class="col s4 m3 grey-text text-darken-2">
                                City
                            </div>
                            <div class="col s8 m9">
                                {{selected_city.name}}
                            </div>
                        </div>
                        <div v-if="hasSelectedWard" class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                            <div class="col s4 m3 grey-text text-darken-2">
                                Ward
                            </div>
                            <div class="col s8 m9">
                                {{selected_ward.name}}
                            </div>
                        </div>
                        <div  class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                            <div class="col s4 m3 grey-text text-darken-2">
                                Address
                            </div>
                            <div class="col s8 m9">
                                {{provided_address}}
                            </div>
                        </div>
                    </div>
                    <div v-if="hasProvidedMessage" class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                        <div class="col s4 m3 grey-text text-darken-2">
                            Message
                        </div>
                        <div class="col s8 m9">
                            {{provided_message }}
                        </div>
                    </div>
                    <div v-if="hasSelectedOptions" class="col s12 padding-top-half padding-top-half padding-bottom-half left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                        <div class="col s4 m3 grey-text text-darken-2">
                            Options
                        </div>
                        <div class="col s8 m9">
                            <div class="collection transparent no-border vert-offset-top-0 vert-offset-bottom-0">
                                <div
                                        v-for="(item, index) in selected_options"
                                        :key="index"
                                        class="collection-item transparent padding-top-0 padding-bottom-half padding-right-half padding-left-half vert-offset-bottom-half">{{ item.name }}</div>
                            </div>

                        </div>
                    </div>
                    <div v-if="hasPicture" class="col s12 padding-top-half padding-top-half padding-bottom-half  left-align border-bottom-1 grey-border border-lighten-1 vert-offset-bottom-1">
                        <div class="col s4 m3 grey-text text-darken-2">
                            Picture
                        </div>
                        <div class="col s8 m9">
                            <img :src="picture.dataUrl" class="responsive-img" alt="Picture" />
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                    <div class="col s6 m4 l3">
                        <button class="btn white grey-text text-darken-2 font-10 col s12"
                                @click.prevent="clearForm" >
                            Cancel
                        </button>
                    </div>
                    <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                        <button type="submit"
                                @click.prevent="submitForm"
                                class="btn grey darken-2 font-10 col s12"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import InputComponent from "./materiallize/InputComponent";
    import http from "../http-common"
    import {mapState} from "vuex"

    export default{
        name: "Summary",
        components: {
            InputComponent

        },
        data()
        {
            return {
                mobile:"",
                selected_comp:false,
                selected_component:"",
                showBranch: false,
                showOther: false,
                organisationname: this.$route.params.alias,
                errors:[],
                load_nextcomponent:false,
                compatibility: false,
                addmobilefield:false,
                show_contact_form: false,
                show_summary_details: false,
                snapshot_content: {},
                contact_me: 0

            }
        },
        methods: {
            restartView: function()
            {
                this.mobile = "";
                this.hideContactForm();
                this.hideSummaryDetails();
            },
            submitForm: function ()
            {
              this.$store.dispatch("START_PROCESSING");
              let _arrOptions = new Array(), _i=0;
              if(this.hasSelectedOptions)
              {
                  for(_i=0;_i<this.selected_options.length;_i++)
                  {
                      _arrOptions.push(this.selected_options[_i].key);
                  }
              }
                return new Promise(resolve => {
                    http({
                        url: "tipoff/save",
                        data: {
                            organisation: this.selected_organisation.key,
                            district: ((this.hasSelectedDistrict)?this.selected_district.key:null),
                            city: ((this.hasSelectedCity)?this.selected_city.key:null),
                            ward: ((this.hasSelectedWard)?this.selected_ward.key:null),
                            location: ((this.hasSelectedLocation)?this.selected_location.key:null),
                            site: ((this.hasSelectedSite)?this.selected_site.key:null),
                            department: ((this.hasSelectedDepartment)?this.selected_department.key:null),
                            image: ((this.hasPicture)?this.picture.dataUrl:null),
                            type: ((this.hasSelectedType)?this.selected_type.key:null),
                            category: ((this.hasSelectedCategory)?this.selected_category.key:null),
                            msisdn: this.mobile,
                            address: ((this.hasProvidedAddress)?this.provided_address:null),
                            options: _arrOptions,
                            latitude: this.provided_latitude,
                            longitude: this.provided_longitude,
                            message: this.provided_message,
                            contact_me: this.contact_me


                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {
                            let _arrMessages = new Array();
                            this.$store.dispatch("STOP_PROCESSING");
                            _arrMessages.push('Report has been submitted successfully');
                            _arrMessages.push("For enquiries use Ref Code #"+resp.data.data);
                            if(typeof resp.data.messages !== "undefined" && resp.data.messages !== null && resp.data.messages.length > 0)
                            {
                                for(let _i=0;_i<resp.data.messages.length;_i++)
                                {
                                    _arrMessages.push(resp.data.messages[_i]);
                                }
                            }
                            this.$store.dispatch("GET_MESSAGES",_arrMessages);
                            this.clearForm();
                            resolve(resp);
                        })
                        .catch(response => {
                            /*console.log("ErrResponse: " + JSON.stringify(response));*/
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof  response.data !== 'undefined' && response.data !== null && typeof  response.data.messages !== 'undefined' && response.data.messages !== null)
                            {
                                _arrMessages = response.data.messages;
                            }
                            else if(typeof  response.message !== 'undefined' && response.message !== null)
                            {
                                _arrMessages.push(response.message);
                            }
                            else
                            {
                                _arrMessages.push("An error occurred, please try again later.");
                            }
                            this.$store.dispatch("GET_MESSAGES", _arrMessages);
                            resolve(response);
                        });
                });
            },
            showContactForm: function()
            {
                this.show_contact_form = true;
                this.contact_me = 1;
                if(this.hasProvidedMobile)
                {
                    this.mobile = this.provided_mobile;
                    this.showSummaryDetails();
                }
            },
            showSummaryDetails: function()
            {
                this.show_summary_details = true;
            },
            hideContactForm: function()
            {
                this.show_contact_form = false;
            },
            hideSummaryDetails: function()
            {
                this.show_summary_details = false;
            },
            providemobilenumber: function () {
                this.addmobilefield = true
            },
            nomobilenumber: function () {
                this.addmobilefield = false
            },

            loadOther: function()
            {   this.selected_comp = true;
                //this.$store.dispatch('SET_SELECTED_OFFENSES', this.list_offenses);
                this.selected_component = 'Other';
            },
            loadBranch: function()
            {
                this.selected_comp = true;
                //this.$store.dispatch('SET_SELECTED_OFFENSES', this.list_offenses);
                this.selected_component = 'Branch_site';
            },
            loadPicture: function ()
            {
                if(this.hasPicture )
                {
                    this.snapshot_content = this.$refs.snapshot;
                    let _objImage = new Image();
                    _objImage.src = this.picture.dataURL;
                    _objImage.className = "responsive-img";
                   /* _objImage.width = this.cameraSensor.width;
                    _objImage.height = this.cameraSensor.height;*/
                    this.snapshot_content.append(_objImage);
                }

            },
            clearForm: function (){
                this.$store.dispatch('SET_SELECTED_CATEGORY', null);
                this.$store.dispatch('SET_SELECTED_TYPE', null);
                this.$store.dispatch('SET_SELECTED_OPTIONS', null);
                this.$store.dispatch('SET_SELECTED_DEPARTMENT', null);
                this.$store.dispatch('SET_SELECTED_SITE', null);
                this.$store.dispatch('SET_SELECTED_WARD', null);
                this.$store.dispatch('SET_SELECTED_CITY', null);
                this.$store.dispatch('SET_SELECTED_DISTRICT', null);
                this.$store.dispatch('SET_SAVE_PICTURE', null);
                this.$store.dispatch('SET_PROVIDED_MESSAGE', null);
                this.$store.dispatch('SET_PROVIDED_MOBILE', null);
                this.$store.dispatch('SET_PROVIDED_ADDRESS', null);
                this.$store.dispatch('SET_PROVIDED_LATITUDE', null);
                this.$store.dispatch('SET_PROVIDED_LONGITUDE', null);
                this.$store.dispatch('SET_SELECTED_LOCATION', null);
                this.$store.dispatch('SET_SHOW_CAMERA', false);
                this.$store.dispatch('SET_SHOW_SUMMARY', false);

                this.$store.dispatch("SET_SELECTED_ACTION_REPORT", false);
                this.$store.dispatch("SET_SELECTED_ACTION_STATUS", false);
            }


        },
        computed: {
            phoneIsValid :function () {

                return this.hasContactNumber && this.mobile.isNumber() && this.mobile.startsWith(7) && this.mobile.length === 8;
            },
            hasContactNumber: function()
            {
                return (typeof this.mobile !== "undefined" && this.mobile !== null && this.mobile.length > 0 && this.mobile.length <= 15);
            },
            hasSelectedCategory: function()
            {
                return (typeof this.selected_category !== "undefined" && this.selected_category !== null);
            },
            hasSelectedSite: function()
            {
                return (typeof this.selected_site !== "undefined" && this.selected_site !== null);
            },
            hasSelectedDepartment: function()
            {
                return (typeof this.selected_department !== "undefined" && this.selected_department !== null);
            },
            hasSelectedCity: function()
            {
                return (typeof this.selected_city !== "undefined" && this.selected_city !== null);
            },
            hasSelectedDistrict: function()
            {
                return (typeof this.selected_district !== "undefined" && this.selected_district !== null);
            },
            hasSelectedLocation: function()
            {
                return (typeof this.selected_location !== "undefined" && this.selected_location !== null);
            },
            hasSelectedWard: function()
            {
                return (typeof this.selected_ward !== "undefined" && this.selected_ward !== null);
            },
            hasPicture: function()
            {
                return (typeof this.picture !== "undefined" && this.picture !== null);
            },
            hasSelectedOptions: function()
            {
                return (typeof this.selected_options !== "undefined" && this.selected_options !== null);
            },
            hasSelectedType: function()
            {
                return (typeof this.selected_type !== "undefined" && this.selected_type !== null);
            },
            hasProvidedMessage: function()
            {
                return (typeof this.provided_message !== "undefined" && this.provided_message !== null && this.provided_message.length > 0);
            },
            hasProvidedAddress: function()
            {
                return (typeof this.provided_address !== "undefined" && this.provided_address !== null);
            },
            hasProvidedMobile: function()
            {
                return (typeof this.provided_mobile !== "undefined" && this.provided_mobile !== null);
            },
            ...mapState({
                selected_category: state =>  state.selected_category,
                selected_site: state =>  state.selected_site,
                selected_organisation: state =>  state.selected_organisation,
                selected_department: state =>  state.selected_department,
                selected_options: state =>  state.selected_options,
                selected_type: state =>  state.selected_type,
                provided_message: state =>  state.provided_message,
                provided_address: state =>  state.provided_address,
                provided_latitude: state =>  state.provided_latitude,
                provided_longitude: state =>  state.provided_longitude,
                selected_location: state =>  state.selected_location,
                selected_district: state => state.selected_district,
                selected_city: state => state.selected_city,
                selected_ward: state => state.selected_ward,
                picture: state => state.picture,
                provided_mobile: state =>  state.provided_mobile,
            }),
        },
        created() {

        },
        mounted()
        {


        },
        watch: {
           /* 'picture' : function (_value)
            {
                console.log("Picture Watcher change" + JSON.stringify(_value));
                this.loadPicture();
            }*/


        }
    }
</script>
<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }

    .card-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;
    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>