<template>
    <div class="row vert-offset-top-1 ">
        <div class="row justify-content-center" v-show="selected_comp == false">
            <div class="col s12 l10 offset-l1">
                <div class="card-title center padding-top-half">
                    <div class="col s12 center font-20" v-if="found_organisation">
                        <img height="64" v-if="organisationHasLogo" v-bind:src="selected_organisation.logo" />
                        {{selected_organisation.name}}

                    </div>
                </div>
             <div v-if="this.selected_report === false && this.selected_status === false" class="col s12 grey lighten-4 card ">
                 <div  class="card-title col s12 vert-offset-top-1 vert-offset-bottom-1 text-align-center">
                     <h5>What do you wish to do?</h5>
                 </div>

                 <div class="card-content col s12 text-align-center">
                     <div class="col s12 m5 l3 offset-m1 offset-l3">
                         <button
                                 class="col s12 btn grey darken-2 vert-offset-top-half center font-10"
                                 @click.prevent="submitReport"
                         >
                             Submit new Report
                         </button>
                     </div>
                     <div class="col s12 m5 l3">
                         <button
                                 class="col s12 btn grey darken-2 vert-offset-top-half center font-10"
                                 @click.prevent="viewStatus"
                         >
                             Check my Report Status
                         </button>
                     </div>

                 </div>
             </div>

             <organisation-view-select v-if="this.selected_report === true" ></organisation-view-select>
             <check-status v-if="this.selected_status=== true"></check-status>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapState} from "vuex";
    import OrganisationViewSelect from "./OrganisationviewComponent";
    import CheckStatus from "./CheckStatus";
    import http from "../http-common"
    export default {
        name: "Home",
        props:['sites'],
        components:{
            "OrganisationViewSelect":OrganisationViewSelect,
            "CheckStatus":CheckStatus
        },
        data(){
            return{
                selected_department_key:"",
                selected_comp:false,
                selected_component:"",
                list_departments: [],

            }
        },
        created()
        {
            this.getOrganisation();
        },
        methods:{
          /*  setSelectedDepartment: function()
            {
                this.selected_department = item;
            },*/
            submitReport: function()
            {
                this.$store.dispatch("SET_SELECTED_ACTION_REPORT", true);
                this.$store.dispatch("SET_SELECTED_ACTION_STATUS", false);
            },
            viewStatus: function()
            {
                this.$store.dispatch("SET_SELECTED_ACTION_STATUS", true);
                this.$store.dispatch("SET_SELECTED_ACTION_REPORT", false);
            },
            getOrganisation: function() {
                this.$store.dispatch("START_PROCESSING");
                return new Promise(resolve => {
                    http({
                        url: "/tipoff/organisation/show",
                        data: {
                            organisation: this.$route.params.alias,
                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {
                            this.$store.dispatch("STOP_PROCESSING");

                            /* console.log("Response:" + JSON.stringify(resp));
                             console.log("token:" + JSON.stringify(resp.data));*/

                            this.$store.dispatch("SET_SELECTED_ORGANISATION", resp.data.data.organisation );
                            this.$store.dispatch("SET_LIST_CATEGORIES", resp.data.data.tipoff.categories);
                            this.$store.dispatch("SET_LIST_OPTIONS", resp.data.data.tipoff.options );
                            this.$store.dispatch("SET_LIST_LOCATIONS", resp.data.data.tipoff.locations );
                            this.$store.dispatch("SET_LIST_TYPES", resp.data.data.tipoff.types );
                            this.$store.dispatch("SET_CONTACT_FORM", resp.data.data.tipoff.contact );

                            resolve(resp);
                        })
                        .catch(response => {
                            /*console.log("ErrResponse: " + JSON.stringify(response));*/
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof  response.data !== 'undefined' && response.data !== null && typeof  response.data.messages !== 'undefined' && response.data.messages !== null)
                            {
                                _arrMessages = response.data.messages;
                            }
                            else if(typeof  response.message !== 'undefined' && response.message !== null)
                            {
                                _arrMessages.push(response.message);

                            }
                            else
                            {
                                _arrMessages.push("An error occurred, please try again later.");
                            }
                            this.$store.dispatch("GET_MESSAGES", _arrMessages);

                            resolve(response);
                        });
                });
            },
        },
        computed: {
            ...mapState({
                selected_organisation: state => state.selected_organisation,
                selected_category: state =>  state.selected_category,
                selected_report: state =>  state.selected_action_report,
                selected_status: state =>  state.selected_action_status,
            }),
            isStatusSelected: function()
            {
                return (typeof this.selected_status == "undefined" && this.selected_status == null);
            },
            isReportSelected: function()
            {
                return (typeof this.selected_report == "undefined" && this.selected_report == null);
            },
            isCategorySelected: function()
            {
                return (typeof this.selected_category !== "undefined" && this.selected_category !== null &&
                    typeof this.selected_category.alias !== "undefined" && this.selected_category.alias !== null
                );
            },
            isBranchSelectedCategory()
            {
                return (this.isCategorySelected && this.selected_category.alias === "branch-site");
            },
            isOtherSelectedCategory()
            {
                return (this.isCategorySelected && this.selected_category.alias === "other");
            },
            isActionSelected()
            {
                return (this.isStatusSelected && this.isReportSelected);
            },
            isStatusChecked()
            {
                return (this.isStatusSelected && this.isReportSelected);
            },
            found_organisation: function(){
                return typeof this.selected_organisation !== "undefined" && this.selected_organisation !== null;

            },
            organisationHasLogo: function()
            {
                return (this.found_organisation && typeof this.selected_organisation.logo !== "undefined" && this.selected_organisation.logo !== null);
            },
        },
        watch: {
            'selected_department_key' : function(_value)
            {
                this.selected_department = null;
                if(typeof _value !== "undefined" && _value !== null)
                {
                    var _arrFound = this.selected_site.departments.data.filter(function(_item)
                    {
                        return (_item.key == _value);
                    });
                    if(_arrFound.length > 0)
                    {
                        this.selected_department = _arrFound[0];
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>