<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">

        <div class="col s12  vert-offset-bottom-1 text-align-center">
            <h5>Which branch do you wish to report?</h5>
        </div>


        <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half">
            <div v-if="hasSites" class="col s12 text-align-center">
                <custom-select-component :options="selected_organisation.sites.data" title="Select Site" v-model="selected_site_key" ref="obj_select_site"></custom-select-component>
            </div>
        </div>
        <div class="row">
            <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                <div class="col s6 m4 l3">
                    <button
                            class="btn white grey-text text-darken-2 font-10 col s12"
                            @click.prevent="clearSelectedCategory"
                    >
                        <i class="material-icons left">chevron_left</i>
                        Back
                    </button>
                </div>
                <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                    <button
                            :disabled="!hasSelectedSite"
                            class="btn grey darken-2 font-10 col s12"
                            @click="selectSite"
                    >
                        Continue
                        <i class="material-icons right">chevron_right</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomSelectComponent from "@/components/materiallize//CustomSelectComponent";
import {mapState} from "vuex";
/*import Departments from "@/components/DepartmentsComponent.vue"*/

  export default{
     props:['sites'],

      components: {
        /*'Departments':Departments*/
          CustomSelectComponent
      },
      data()
      {
       return {
           component:"",
           site_search_key: "",
           disable:false,
           stage:[],
           selected_site : null,
           selected_site_key:"",
           results: [],
           selected_option: false,
           load_component:false,
           selected_comp:false,
           selected_component:"",
       }
      },
      methods: {
          selectSite: function()
          {
              this.$store.dispatch("SET_SELECTED_SITE", this.selected_site);
          },
          clearSelectedCategory: function()
          {
              this.$store.dispatch('SET_SELECTED_CATEGORY', null);
          },

          searchSite: function() {
              console.log("Search site array");
              }
          },



      computed: {
         hasOrganisation: function()
         {
             return (typeof this.selected_organisation !== "undefined" && this.selected_organisation !== null);
         },
         hasSites: function()
         {
             return (this.hasOrganisation && typeof this.selected_organisation.sites !== "undefined" && this.selected_organisation.sites !== null && typeof this.selected_organisation.sites.data !== "undefined" && this.selected_organisation.sites.data !== null);
         },
          hasSelectedSite: function()
          {
              return (this.hasSites && typeof this.selected_site !== "undefined" && this.selected_site !== null);
          },
          ...mapState({
              selected_organisation: state => state.selected_organisation,
          })
      },
      created() {

      },

      watch: {
          'selected_site_key' : function(_value)
          {
              this.selected_site = null;
              if(typeof _value !== "undefined" && _value !== null)
              {
                  var _arrFound = this.selected_organisation.sites.data.filter(function(_item)
                  {
                      return (_item.key == _value);
                  });
                  if(_arrFound.length > 0)
                  {
                      this.selected_site = _arrFound[0];
                  }
              }
          },

          no_results: function() {
                  if (this.no_results) {
                      console.log("Organisations: " + this.organisation.length);
                      if (
                          typeof this.organisation == "undefined" ||
                          this.organisation == null ||
                          this.organisation.length <= 0
                      ) {
                          console.log("No Results watch");

                          this.getOrganisations();
                      }
                  }
              },
          selected_organisation: function(_value)
          {
              if(typeof _value !== "undefined" && _value !== null)
              {
                  this.sites.list = _value.sites.data;
              }
              console.log("LIST SITES: " +JSON.stringify(this.sites.list));
          }
      },
      mounted()
      {
        /*this.compatibility = this.checkCompatibility();
        this.page_loaded = true;*/

      },



  }
</script>
<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>