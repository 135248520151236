<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">

        <div class="col s12  vert-offset-bottom-1 text-align-center">
            <h5>Which department do you wish to report?</h5>
        </div>

        <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half">
            <div v-if="hasSelectedSite" class="col s12 text-align-center">
                <custom-select-component :options="selected_site.departments.data" title="Select Department" v-model="selected_department_key" ref="obj_select_department"></custom-select-component>
            </div>
        </div>
        <div class="row">
            <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                <div class="col s6 m4 l3">
                    <button
                            class="btn white grey-text text-darken-2 font-10 col s12"
                            @click.prevent="clearSelectedSite"
                    >
                        <i class="material-icons left">chevron_left</i>
                        Back
                    </button>
                </div>
                <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                    <button
                            :disabled="!hasSelectedDepartment"
                            class="btn grey darken-2 font-10 col s12"
                            @click="selectDepartment"
                    >
                        Continue
                        <i class="material-icons right">chevron_right</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import CustomSelectComponent from "@/components/materiallize//CustomSelectComponent";
    export default {
        name: "Departments",
        props:['sites'],
        components:{
            CustomSelectComponent
        },
        data(){
            return{
                selected_department_key:"",
                selected_department:null,
                selected_comp:false,
                selected_component:"",
                list_departments: [],

            }
        },
        methods:{
            clearSelectedSite: function()
            {
                this.$store.dispatch("SET_SELECTED_SITE", null);
            },
            selectDepartment: function()
            {
                this.$store.dispatch("SET_SELECTED_DEPARTMENT", this.selected_department);
            },
        },
        computed: {
            ...mapState({
                selected_site: state => state.selected_site,
            }),
            nextComponentSelected: function()
            {
                return (typeof this.selected_component !== "undefined" && this.selected_component !== null && this.selected_component.length > 0);
            },
            hasSelectedSite: function()
            {
                return (typeof this.selected_site !== "undefined" && this.selected_site !== null);
            },
            hasDepartments: function()
            {
                return (this.hasSelectedSite && typeof this.selected_site.departments !== "undefined" && this.selected_site.departments !== null && typeof this.selected_site.departments.data !== "undefined" && this.selected_site.departments.data !== null && this.selected_site.departments.data.length > 0);
            },
            hasSelectedDepartment: function()
            {
                return (this.hasDepartments && typeof this.selected_department !== "undefined" && this.selected_department !== null);
            },
        },
        watch: {
            'selected_department_key' : function(_value)
            {
                this.selected_department = null;
                if(typeof _value !== "undefined" && _value !== null)
                {
                    var _arrFound = this.selected_site.departments.data.filter(function(_item)
                    {
                        return (_item.key == _value);
                    });
                    if(_arrFound.length > 0)
                    {
                        this.selected_department = _arrFound[0];
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>