<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div
         ref="div_container"
         class="col s12 no-padding clickable"
         :id="id"
    >
        <div ref="div_selected"
             class="col s12 no-padding border-bottom-1 grey-border font-12 align-middle"
             v-show="!is_selected"
        >
            <div
                    @click="triggerSelection"
                    class="col s11 content font-size-l padding-bottom-1"
            >
                {{selected_title}}
            </div>
            <div @click="triggerSelection"
                 v-show="!is_expanded"
                 class="col s1 no-padding center-align close padding-top-half padding-bottom-half"
            >
                <i class="material-icons">expand_more</i>
            </div>
            <div @click="clearSelection"
                 v-show="is_expanded"
                 class="col s1 no-padding center-align close padding-top-half padding-bottom-half"
            >
                <i class="material-icons tiny">close</i>
            </div>
        </div>
        <div ref="div_select_box"
             class="col s12 no-padding position-relative"
             v-if="(is_selected == true)"
        >
            <div class="col s12 no-padding">
                <div class="col s10 input-field vert-offset-top-0">
                    <input ref="input_filter"
                           type="text"
                           data-length="100"
                           v-model="filter_value"
                           :id="input_filter_id"
                           v-on:keyup.enter.prevent="onEnter"
                    />
                </div>
                <div class="col s2">
                    <a @click.prevent="cancelSelection"
                       ref="btn_cancel_selection"
                       class="answer btn btn-tiny no-padding center-align close grey black-text">
                        <i class="material-icons tiny">close</i>
                    </a>
                </div>

            </div>

            <div class="col s12 no-padding dropdown-content">
                <div
                        ref="div_select_list"
                        class="col s12 no-padding"
                >
                    <div v-for="opt in options"
                         :key="opt.key"
                         :value="opt.key"
                         class="col s12 no-padding" v-show="arrVisibleOptions.indexOf(opt)>=0">
                        <a @click.prevent="selectOption(opt)"
                           class="answer btn btn-block btn-option bold vert-offset-bottom-1 white black-text font-10"

                        >
                            {{ getName(opt)}}
                        </a>
                    </div>
                </div>

                <div class="col s12 no-padding vert-offset-top-half">
                    <a @click.prevent="cancelSelection"
                       class="answer btn btn-tiny btn-block bold vert-offset-bottom-1 grey black-text font-10" >
                        Cancel
                    </a>
                </div>
            </div>

        </div>
        <input
                type="hidden"
                ref="input_value"
                :value="value"

        />
    </div>
</template>

<script>
    export default {
        name: "custom-select-component",
        props: {
            title: {
                type: String
            },
            options: {
                type: Array
            },
            id:
                {
                type:String,
                    default:""
            },
            value: {
                type: [Number, String],
                default: ""
            },
            searchStart:{
                type:Boolean,
                default:true
            }
        },
        data() {
            return {
                instance: undefined,
                is_selected : false,
                is_expanded: false,
                arrVisibleOptions: [],
                filter_value: "",
                selected_option: null,
                selected_title: "",
                selected_value : ""
            };
        },
        mounted() {
            this.selected_value = this.value;
            this.init();
            this.$el.addEventListener("change", this.changeHandler);
        },
        methods: {

            getName(option)
            {
                if(typeof option.name !== "undefined" && option.name !== null)
                {
                    return option.name;
                }
                else if(typeof option.title !== "undefined" && option.title !== null)
                {
                    return option.title;
                }
                else
                {
                    return "[Invalid Option]";
                }
            },
            selectOption(option)
            {
                this.selected_option = option;
                this.is_expanded = true;
                this.is_selected = false;
            },
            cancelSelection()
            {
                this.is_selected = false;
                this.filter_value = "";
            },
            clearSelection()
            {
                this.selected_title = this.title;
                this.selected_value = "";
                this.is_expanded = false;
                this.filter_value = "";
            },
            expandSelection()
            {
                this.$refs.content_selector.trigger("click");
            },
            triggerSelection()
            {

                this.is_selected = true;
                /*if(typeof this.input_filter_id !== "undefined" && this.input_filter_id !== null && this.input_filter_id.length > 0)
                {
                    console.log("trigger selection");

                    window.document.getElementById(this.input_filter_id).focus();
                }*/
                //this.$refs.input_filter.focus();


            },
            onEnter:function(){
                if(typeof this.arrVisibleOptions !== "undefined" && this.arrVisibleOptions !== null && this.arrVisibleOptions.length > 0)
                {
                    this.selectOption(this.arrVisibleOptions[0]);
                }
            },

            initTitle()
            {
                if(typeof this.title !== "undefined" && this.title !== null && this.title.length > 0)
                    this.selected_title = this.title;
                else
                    this.selected_title = "Select Option";
                this.selected_value = "";
            },
            init()
            {
                this.instance = this;
                this.arrVisibleOptions = this.options;
                this.initTitle();
                if(typeof this.value !== "undefined" && this.value !== null && this.value.length > 0)
                {
                    var _instance = this;
                    var _filteredList = this.arrVisibleOptions.filter(function(_item)
                    {
                        return ((typeof _item.value !== "undefined" && _item.value !== null && _item.value == _instance.value) || (typeof _item.key !== "undefined" && _item.key !== null && _item.key == _instance.value));
                    });

                    if(_filteredList.length > 0)
                    {
                        this.selected_option = _filteredList[0];
                    }
                }

            },
            changeHandler({ target }) {
                /*console.log("Input Change: " + target.value);
               */ this.$emit("input", target.value);
            },

        },
        computed:{
            'input_filter_id':function () {

                if(typeof this.id === "undefined" || this.id === null || this.id.length <= 0)
                {
                    return null;
                }

                return this.id+"_input_filter";

            }
        },
        watch:
        {
            'options':function (_value) {
                this.arrVisibleOptions = _value;
                this.init();
            },
            'filter_value': function(_value)
            {

                if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                {
                    this.arrVisibleOptions = this.options;
                    return false;
                }

                let $this = this;
                this.arrVisibleOptions = this.options.filter(function(_item)
                {
                    return ((!$this.searchStart && _item.name.toLowerCase().includes(_value.toLowerCase()) ) || _item.name.toLowerCase().startsWith(_value.toLowerCase()));
                });

            },
            'value' : function(_value)
            {
                if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                {
                    this.initTitle();
                }
            },
            'selected_value': function(_value)
            {
                this.$emit("input", _value);
                if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                    this.arrVisibleOptions = this.options;
            },
            'selected_option': function(_value)
            {
                if(typeof _value === "undefined" || _value === null || _value.length <= 0)
                {
                    this.initTitle();
                }
                else
                {
                    if(typeof _value.key !== "undefined" && _value.key !== null)
                    {
                        this.selected_title = _value.name;
                        this.selected_value = _value.key;
                    }
                    else if(typeof _value.value !== "undefined" && _value.value !== null)
                    {
                        this.selected_title = _value.title;
                        this.selected_value = _value.value;
                    }

                }
            }
        },
        destroyed() {
            this.$el.removeEventListener("change", this.changeHandler);
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .btn-option
    {
        line-height: normal !important;
        height: auto !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        margin-bottom:2px !important;
    }

    .dropdown-content {
        display: block;
        left: 0px;
        top: 50px;
        max-height: 250px;
        transform-origin: 0px 0px 0px;
        opacity: 1;
        transform: scaleX(1) scaleY(1);
        background-color: #fff;
        margin: 0;
        min-width: 100px;
        overflow-y: auto;
        position: absolute;
        z-index: 9999;
    }
    .position-relative{
        position: relative;
    }

</style>