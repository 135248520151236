<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">
        <div class="col s12  vert-offset-bottom-1 text-align-center">
            <h5>What is your mobile number?</h5>
        </div>

        <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half">
            <div class="col s12 text-align-center">
                <input-component v-model="mobile" label="Enter mobile number here "  type="tel"></input-component>
            </div>
        </div>
        <div class="row">
            <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                <div class="col s6 m4 l3">
                    <button
                            class="btn white grey-text text-darken-2 font-10 col s12"
                            @click.prevent="clearSelectedCategory"
                    >
                        <i class="material-icons left">chevron_left</i>
                        Back
                    </button>
                </div>
                <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                    <button
                            class="btn grey darken-2 font-10 col s12"
                            :disabled="!phoneIsValid"
                            @click.prevent="submitForm"
                    >
                        Continue
                        <i class="material-icons right">chevron_right</i>
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import InputComponent from "./materiallize/InputComponent";
    import {mapState} from "vuex"

    export default{
        name: "ContactForm",
        components: {
            InputComponent

        },
        data()
        {
            return {
                mobile:"",
                selected_comp:false,
                selected_component:"",
                errors:[],
                load_nextcomponent:false,


            }
        },
        methods: {

            submitForm: function ()
            {

                this.$store.dispatch('SET_PROVIDED_MOBILE', this.mobile);
            },
            clearSelectedCategory: function()
            {
                this.$store.dispatch('SET_SELECTED_CATEGORY', null);
            },

        },
        computed: {
            phoneIsValid :function () {

                return this.hasContactNumber && this.mobile.isNumber() && this.mobile.startsWith(7) && this.mobile.length === 8;
            },
            hasContactNumber: function()
            {
                return (typeof this.mobile !== "undefined" && this.mobile !== null && this.mobile.length > 0 && this.mobile.length <= 15);
            },
            hasProvidedMobile: function()
            {
                return (typeof this.provided_mobile !== "undefined" && this.provided_mobile !== null);
            },
            ...mapState({
                selected_organisation: state =>  state.selected_organisation,
                provided_mobile: state =>  state.provided_mobile,
            }),
        },
        created() {

        },
        mounted()
        {


        },
        watch: {


        }
    }
</script>
<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }

    .card-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;
    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>