<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">

        <div v-if="!show_location"  class="col s12 no-padding">
            <div class="col s12  vert-offset-bottom-1 text-align-center">
                <h5>What is the location of the issue?</h5>
            </div>
            <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half " >
                <div class="row col s12" v-if="hasDistricts">
                    <custom-select-component :options="list_districts" title="Select District" v-model="selected_district_key" ref="obj_select_district"></custom-select-component>
                </div>
                <div class="row col s12" v-if="hasSelectedDistrict && hasCities" >
                    <custom-select-component :options="list_cities" title="Select City" v-model="selected_city_key" ref="obj_select_city"></custom-select-component>
                </div>
                <div class="row col s12" v-if="hasSelectedCity && hasWards" >
                    <custom-select-component :options="list_wards" title="Select Ward" v-model="selected_ward_key" ref="obj_select_ward"></custom-select-component>
                </div>
                <div class="row col s12" v-if="hasLocations && hasSelectedCity">
                    <input-component  type="text"  v-model="address" label="Can you be more specific about the location here?"></input-component>
                </div>
                <div class="row col s12" v-if="canGeoLocate && hasLocations && hasSelectedCity">
                    <div class="col s12 m9 l8">
                        <h6>Do you wish to add GPS coordinates?</h6>
                    </div>
                    <div class="col s12 m3 l4 no-padding">
                        <button
                                class="btn grey darken-2 font-10 col s12"
                                @click.prevent="locateMe"
                        >
                            Get GPS
                        </button>
                    </div>
                    <div class="col s12" v-if="hasValidGeoLocation">
                        {{latitude}},{{longitude}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                    <div class="col s6 m4 l3">
                        <button
                                class="btn white grey-text text-darken-2 font-10 col s12"
                                @click.prevent="clearSelectedCategory"
                        >
                            <i class="material-icons left">chevron_left</i>
                            Back
                        </button>
                    </div>
                    <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                        <button
                                :disabled="!hasAddress"
                                class="btn grey darken-2 font-10 col s12"
                                @click.prevent="showLocationForm"
                        >
                            Continue
                            <i class="material-icons right">chevron_right</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="show_location "  class="col s12 no-padding">
            <div class="col s12  vert-offset-bottom-1 text-align-center">
                <h5>What are you reporting?</h5>
            </div>
            <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half " >
                <div class="row col s12" >
                    <custom-select-component :options="list_locations" title="Select Location" v-model="selected_location_key" ref="obj_select_location"></custom-select-component>
                </div>
            </div>
            <div class="row">
                <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                    <div class="col s6 m4 l3">
                        <button
                                class="btn white grey-text text-darken-2 font-10 col s12"
                                @click.prevent="clearAddressData"
                        >
                            <i class="material-icons left">chevron_left</i>
                            Back
                        </button>
                    </div>
                    <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                        <button
                                :disabled="!hasSelectedLocation"
                                class="btn grey darken-2 font-10 col s12"
                                @click.prevent="saveLocation"
                        >
                            Continue
                            <i class="material-icons right">chevron_right</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //import SelectComponent from "@/components/materiallize/SelectComponent.vue";
    //import Locationtype from "@/components/Locationtype.vue"
    import {mapState} from "vuex"
    import CustomSelectComponent from "@/components/materiallize//CustomSelectComponent";
    import http from "../http-common"
    import InputComponent from "./materiallize/InputComponent";
    export default {

        name:"LocationSelect",
        components:{
            InputComponent,
            CustomSelectComponent,
           // SelectComponent,
            /*'Locationtype':Locationtype,*/

        },
        data () {
            return{
                location:[],
                errors:[],
                districts : [],
                wards:"",
                district:"",
                selected_district_key : "",
                selected_district : null,
                selected_city_key: "",
                selected_ward: null,
                selected_ward_key: "",
                selected_city: null,
                selected_district_new: null,
                selected_location: null,
                selected_location_key:"",
                list_districts: [],
                list_cities: [],
                list_wards: [],
                latitude: null,
                longitude: null,
                canGeoLocate: true,
                myLocation:null,
                address: null,
                show_location:false,
                show_district:false,
            }
        },
        created(){
            this.getDistricts();
        },
        mounted(){
            this.checkGeoLocation();
        },
        methods:{
            clearSelectedCategory: function()
            {
                this.$store.dispatch('SET_SELECTED_CATEGORY', null);
            },
            clearAddressData: function()
            {
                /*this.$store.dispatch("SET_PROVIDED_LONGITUDE", null);
                this.$store.dispatch("SET_PROVIDED_LATITUDE", null);
                this.$store.dispatch("SET_PROVIDED_ADDRESS", null);
                this.$store.dispatch("SET_SELECTED_WARD", null);
                this.$store.dispatch("SET_SELECTED_CITY", null);
                this.$store.dispatch("SET_SELECTED_DISTRICT", null);*/
                this.show_location = false;
            },
            setProvidedAddress: function()
            {
                this.$store.dispatch("SET_PROVIDED_ADDRESS", this.address);
            },
            selectLocation: function () {
                this.$store.dispatch("SET_SELECTED_LOCATION", this.selected_location);
            },
            selectDistrict: function () {
                this.$store.dispatch("SET_SELECTED_DISTRICT", this.selected_district);
            },
            selectCity: function () {
                this.$store.dispatch("SET_SELECTED_CITY", this.selected_city);
            },
            selectWard: function () {
                this.$store.dispatch("SET_SELECTED_WARD", this.selected_ward);
            },
            setGeoCoordinates(_withMessage)
            {
                if(!this.validateGeoCoordinates())
                {
                    if(typeof _withMessage !== "undefined" && _withMessage !== null && _withMessage === true)
                    {
                        this.$store.dispatch("GET_MESSAGES", ["Invalid GPS Coordinates"]);
                    }
                    return false;
                }
                this.$store.dispatch("SET_PROVIDED_LATITUDE", this.latitude);
                this.$store.dispatch("SET_PROVIDED_LONGITUDE", this.longitude);
            },

            saveLocation: function () {
                this.setProvidedAddress();
                this.selectLocation();
                this.selectDistrict();
                this.selectCity();
                this.selectWard();
                this.setGeoCoordinates();
            },

            validateGeoCoordinates: function()
            {
                return (typeof this.latitude !== "undefined" && this.latitude !== null && this.latitude.isNumber() && this.latitude >= -90 && this.latitude <= 90) &&
                    (typeof this.longitude !== "undefined" && this.longitude !== null && this.longitude.isNumber() && this.longitude >= -180 && this.longitude <= 180)
                    ;
            },
            getDistricts: function() {
                this.$store.dispatch("START_PROCESSING");

                return new Promise(resolve => {
                    http({
                        url: "/registration/districts",
                        data : {

                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {


                            console.log("districts:" + JSON.stringify(resp.data));
                            this.list_districts = resp.data.data;
                            this.list_cities = [];
                            this.list_wards = [];
                            this.$store.dispatch("STOP_PROCESSING");
                            resolve(resp);
                        })
                        .catch(response => {
                            console.log("ErrResponse: " + JSON.stringify(response));
                            this.$store.dispatch("STOP_PROCESSING");
                            this.$store.dispatch("GET_MESSAGES", response.data.messages);

                        });
                });
            },
            getCities: function() {
                this.$store.dispatch("START_PROCESSING");

                return new Promise(resolve => {
                    http({
                        url: "/registration/cities",
                        data : {
                            district: this.selected_district_key,
                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {


                            console.log("cities:" + JSON.stringify(resp.data));
                            this.list_cities = resp.data.data;
                            this.list_wards = [];
                            this.$store.dispatch("STOP_PROCESSING");
                            resolve(resp);
                        })
                        .catch(response => {
                            console.log("ErrResponse: " + JSON.stringify(response));
                            this.$store.dispatch("STOP_PROCESSING");
                            this.$store.dispatch("GET_MESSAGES", response.data.messages);

                        });
                });
            },
            getWards: function() {
                this.$store.dispatch("START_PROCESSING");

                return new Promise(resolve => {
                    http({
                        url: "/registration/wards",
                        data : {
                            city: this.selected_city_key,
                        },
                        method: "POST",
                        timeout: 30000 })
                        .then(resp => {


                            console.log("wards:" + JSON.stringify(resp.data));
                            this.list_wards = resp.data.data;
                            this.$store.dispatch("STOP_PROCESSING");
                            resolve(resp);
                        })
                        .catch(response => {
                            console.log("ErrResponse: " + JSON.stringify(response));
                            this.$store.dispatch("STOP_PROCESSING");
                            this.$store.dispatch("GET_MESSAGES", response.data.messages);

                        });
                });
            },
            showLocationForm: function()
            {
                this.show_location = true;
            },
            showDistrictForm: function()
            {
                this.show_location = true;
            },
            checkGeoLocation: function()
            {
                if (window.navigator.geolocation)
                {
                    this.canGeoLocate = true;
                } else {

                    //this.$ref.near_me.disable();
                    console.log("Geolocation is not supported by this browser.");
                }
            },
            async locateMe()
            {
                let $this = this;
                try
                {

                    this.myLocation = await this.getGeoLocation();
                }
                catch(err)
                {
                    if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError")
                    {
//required track is missing
                        console.log("Device Not Found");
                        $this.$store.dispatch('GET_MESSAGES', ["Device not Found", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "NotReadableError" || err.name == "TrackStartError")
                    {
//webcam or mic are already in use
                        console.log("Device Already in use");
                        $this.$store.dispatch('GET_MESSAGES', ["Device already in use by another App", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError")
                    {
                        console.log("Constraints not satisfied");
//constraints can not be satisfied by avb. devices
                        $this.$store.dispatch('GET_MESSAGES', ["Device unable to satisfy required constraints.", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError")
                    {
                        console.log("Permission Denied");
//permission denied in browser
                        $this.$store.dispatch('GET_MESSAGES', ["Browser GeoLocation Access Permission Denied", "If this error persists, try using a different browser"]);
                    }
                    else if (err.name == "TypeError" || err.name == "TypeError")
                    {
                        console.log("Constraint Error");
//empty constraints object
                        $this.$store.dispatch('GET_MESSAGES', ["Constraint Error"]);
                    }
                    else
                    {
                        $this.$store.dispatch('GET_MESSAGES', ["Our application is unable to access your GPS Coordinates", err.message, "If this error persists, try using a different browser"]);
                    }

                    console.log("Geo Error: " + JSON.stringify(err.message));
                }
            },
            async getGeoLocation()
            {
                let $this = this;
                $this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve,reject)=> {
                    if(!$this.canGeoLocate || !("geolocation" in window.navigator ))
                    {
                        $this.$store.dispatch('STOP_PROCESSING');
                        reject(new Error("GeoLocation is not available"));
                    }
                    window.navigator.geolocation.getCurrentPosition(pos => {
                            $this.$store.dispatch('STOP_PROCESSING');
                            resolve(pos);
                        },
                        err => {
                            $this.$store.dispatch('STOP_PROCESSING');
                            reject(err);
                        },
                        {
                            enableHighAccuracy: true,
                            maximumAge: 30000,
                            timeout: 27000
                        });
                });

            },

        },
        computed:{
            hasSelectedDistrict: function () {
                return typeof this.selected_district !== 'undefined' && this.selected_district !== null;
            },
            hasSelectedCity: function () {
                return typeof this.selected_city !== 'undefined' && this.selected_city !== null;
            },
            hasSelectedLocation: function () {
                return typeof this.hasSelectedDistrict !== 'undefined' && this.hasSelectedCity!== null && this.selected_location !== null;
            },
            hasOrganisation: function()
            {
                return (typeof this.selected_organisation !== "undefined" && this.selected_organisation !== null);
            },
            hasDistricts: function () {
                return typeof  this.list_districts !== 'undefined' && this.list_districts !== null && this.list_districts.length > 0;
            },
            hasCities: function () {
                return typeof this.list_cities !== 'undefined' && this.list_cities !== null && this.list_cities.length > 0;
            },
            hasWards:  function () {
                return typeof this.list_wards !== 'undefined' && this.list_wards !== null && this.list_wards.length > 0;
            },
            hasLocations: function () {
                return typeof this.list_locations !== 'undefined' && this.list_locations !== null && this.list_locations.length > 0;
            },
            hasAddress: function () {
                return typeof this.address !== 'undefined' && this.address !== null ;
            },
            hasValidGeoLocation: function () {
                return this.validateGeoCoordinates();
            },
            ...mapState({
                list_locations: state => state.list_locations,
            }),

        },

        watch: {
            'selected_district_key' : function(_value)
            {

                this.selected_city_key = null;
                this.selected_ward_key = null;
                this.selected_district = null;
                if(typeof _value !== "undefined" && _value !== null)
                {
                    var _arrFound = this.list_districts.filter(function(_item)
                    {
                        return (_item.key == _value);
                    });
                    if(_arrFound.length > 0)
                    {
                        this.selected_district = _arrFound[0];
                        this.getCities();
                    }
                    else
                    {
                        this.list_cities = [];
                    }
                    this.list_wards = [];
                }

            },
            'selected_city_key' : function(_value)
            {
                this.selected_ward = null;
                this.selected_city = null;
                if(typeof _value !== "undefined" && _value !== null)
                {

                    /*this.$refs.obj_select_ward.initializeSelect();*/
                    var _arrFound = this.list_cities.filter(function(_item)
                    {
                        return (_item.key == _value);
                    });
                    if(_arrFound.length > 0)
                    {
                        this.selected_city = _arrFound[0];
                        this.getWards();
                    }
                    else
                    {
                        this.list_wards = [];
                    }
                }
            },

            'selected_ward_key' : function(_value)
            {
                this.selected_ward = null;
                if(typeof _value !== "undefined" && _value !== null)
                {

                    /*this.$refs.obj_select_ward.initializeSelect();*/
                    var _arrFound = this.list_wards.filter(function(_item)
                    {
                        return (_item.key == _value);
                    });
                    if(_arrFound.length > 0)
                    {
                        this.selected_ward = _arrFound[0];
                    }
                }
            },
            'selected_location_key' : function(_value)
            {
                this.selected_location = null;
                if(typeof _value !== "undefined" && _value !== null)
                {

                    /*this.$refs.obj_select_ward.initializeSelect();*/
                    var _arrFound = this.list_locations.filter(function(_item)
                    {
                        return (_item.key == _value);
                    });
                    if(_arrFound.length > 0)
                    {
                        this.selected_location = _arrFound[0];
                    }
                }
            },
            'myLocation' : function(_value)
            {

                if(typeof _value !== "undefined" && _value !== null)
                {
                  if (typeof _value.latitude !== 'undefined' && _value.latitude !== null && _value.longitude !== 'undefined' && _value.longitude !== null)
                  {
                     this.latitude = _value.latitude;
                     this.longitude = _value.longitude;
                  }
                    console.log("my location",JSON.stringify(_value));

                }
            },
        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
