<template>
    <div class="row vert-offset-top-half">
     <home></home>
    <!-- <organisation-view-select  sites_list="organisation.sites"></organisation-view-select>-->
    </div>
</template>

<script>
   /* import OrganisationViewSelect from "../components/OrganisationviewComponent";*/
    import Home from "../components/HomeComponent";

    export default {
        name: "Organisation_view",
        props:{

        },
        components: {

            Home,
           /* "OrganisationViewSelect":OrganisationViewSelect,*/

        },
        data()
        {
            return {
                phone:null,
                component:"",
                disable:false,
                stage:[],
                selected_option: false,
                organisation:{
                    searched:false,
                    found:false,
                    selected:null,
                    sites:null,
                    department:null,
                },

                other:{
                    external_location:true,
                },

                show:false,
                organisationname: this.$route.params.alias,
                errors:[],
                compatibility: false,
                page_loaded: false,
                OSName: "",

            }
        },
        methods: {


            View_Component: function(){
               this.show = true;
            },
            Close_Component: function(){
                this.show = false;
            },
        },
        computed: {
            found_organisation: function(){
                return typeof this.organisation !== "undefined" && this.organisation !== null && this.organisation.searched  == true && this.organisation.found == true;
            },
            found_organisation_sites: function () {
                return this.organisation.sites.length<1;
            }
        },
        created() {

        },
    }

</script>

<style scoped>

</style>