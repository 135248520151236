<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">
        <div v-show="!isStreaming && picture.taken && !submit_report" class="col s12  vert-offset-bottom-1 text-align-center">
            <h5>Picture Preview</h5>
        </div>

        <div class="col s12 m10 offset-m1 padding-top-half padding-bottom-half">
            <div class="col s12 m8 offset-m2">
                <h5 v-show="stageHasContent" class="vert-offset-top-2">{{ stage }}</h5>
                <div v-show="isStreaming" class="col s12" >
                    <video id="camera__view" ref="camera__view" autoplay playsinline></video>
                    <canvas id="camera__sensor" ref="camera__sensor"></canvas>
                    <img src="//:0" alt="" id="camera__output" ref="camera__output" />
                    <button id="trigger" type="button" class="btn btn-large grey darken-2" @click.prevent="capture">Take Picture</button>
                </div>
                <div v-show="!isStreaming && picture.taken && !submit_report" class="col s12 row vert-offset-top-1 center" >
                    <div id="snapshot" ref="snapshot" class="col s12 m8 l6 offset-m2 offset-l3"></div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                <div class="col s6 m4 l3">
                    <button
                            class="col s12 btn grey darken-2 font-10"
                            :disabled="!canSubmit"
                            @click="startStreaming"
                    >
                        Take Another Picture
                    </button>
                </div>
                <div class="col s6 m4 l3 offset-m4 offset-l6 right-align">
                    <button
                            class="col s12 btn grey darken-2 font-10"
                            :disabled="!canSubmit"
                            @click="savePicture"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>



    export default {
        name: 'TakePic',
        components:{

        },
        data() {
            return {
                constraints : {
                    video: {
                        facingMode: "environment"
                    }
                },
                error: false,
                stage: "",
                canStream: false,
                takePicture: false,
                cameraOutput: {},
                cameraView: {},
                cameraSensor: {},
                cameraTrigger: {},
                objStream: null,
                mediaMgr: null,
                snapshot_content: {},
                canSubmit : true,
                isStreaming: false,
                selected_comp:false,
                selected_component:"",
                picture : {
                    dataUrl : null,
                    blob: null,
                    taken:false
                },

                submit_report:false,
                timer: 10,
                country:"BW"

            };
        },
        created()
        {


        },
        mounted()
        {
            let _thisObject = this;
            _thisObject.cameraOutput = _thisObject.$refs.camera__output;
            _thisObject.cameraSensor = _thisObject.$refs.camera__sensor;
            _thisObject.cameraView = _thisObject.$refs.camera__view;
            _thisObject.snapshot_content = _thisObject.$refs.snapshot;

            if(typeof window.navigator === "undefined" || window.navigator === null || typeof window.navigator.mediaDevices === "undefined" || window.navigator.mediaDevices === null)
            {
                _thisObject.error = true;
                _thisObject.stage = 2;
                _thisObject.stage = "There seem to be a problem with the browser you are using, please try a different one.";
                _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                return false;
            }


            if(typeof window.navigator.mediaDevices.getUserMedia === "undefined" || window.navigator.mediaDevices.getUserMedia === null)
            {
                _thisObject.error = true;
                _thisObject.stage = "MediaDevices Access Not Allowed on this browser, please try a different browser.";
                _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                return false;
            }

            _thisObject.canStream = true;
            _thisObject.startStreaming();

        },
        methods: {
            savePicture: function()
            {
                /*console.log('Save Picture'+JSON.stringify(this.picture))*/
                this.$store.dispatch("SET_SAVE_PICTURE", this.picture);
                this.goToSummary();
            },
            goToSummary: function()
            {
                this.$store.dispatch("SET_SHOW_CAMERA", false);
                this.$store.dispatch("SET_SHOW_SUMMARY", true);
            },
            capture: function()
            {
                while (this.snapshot_content.firstChild) {
                    this.snapshot_content.removeChild(this.snapshot_content.firstChild);
                }
                this.cameraSensor.width = this.cameraView.videoWidth;
                this.cameraSensor.height = this.cameraView.videoHeight;
                let context = this.cameraSensor.getContext("2d");
                /*context.scale(-1, 1);
                 context.drawImage(this.cameraView, 0, 0, this.cameraSensor.width*-1, this.cameraSensor.height);*/
                context.drawImage(this.cameraView, 0, 0, this.cameraSensor.width, this.cameraSensor.height);
                /*console.log("Image drawn");*/
                let _objImage = new Image();
                _objImage.src = this.cameraSensor.toDataURL("image/jpeg");
                _objImage.className = "responsive-img";
                _objImage.width = this.cameraSensor.width;
                _objImage.height = this.cameraSensor.height;
                this.snapshot_content.append(_objImage);
                this.picture.dataUrl = this.cameraSensor.toDataURL("image/jpeg");
                this.picture.blob = this.dataURItoBlob(this.picture.dataUrl);
                /*console.log('Picture'+JSON.stringify(this.picture.dataUrl));*/
                this.picture.taken = true;
                this.stopStreaming();
            },
            startStreaming: function()
            {
                let _thisObject = this;
                if(!_thisObject.canStream)
                {
                    _thisObject.error = true;
                    _thisObject.stage = "There seem to be an issue with your Camera.";
                    _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                    return false;
                }

                _thisObject.cameraOutput.src = "//:0";
                _thisObject.cameraSensor.getContext("2d").clearRect(0, 0, _thisObject.cameraSensor.width, _thisObject.cameraSensor.height);
                _thisObject.takePicture = false;
                _thisObject.stage = "We would like to access your Camera to proceed. Please allow us to Continue.";
                _thisObject.$store.dispatch("START_PROCESSING");
                window.navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
                    /* use the stream */
                    _thisObject.stage = "";
                    _thisObject.canStream = true;
                    _thisObject.objStream = stream;
                    _thisObject.cameraView.srcObject = _thisObject.objStream;
                    _thisObject.isStreaming = true;
                    _thisObject.$store.dispatch("STOP_PROCESSING");
                })
                    .catch(function(err) {
                        /* handle the error */
                        console.log(err); /* handle the error */
                        if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError")
                        {
                            _thisObject.$store.dispatch("STOP_PROCESSING");
                            _thisObject.error = true;
                            _thisObject.stage = "No Camera Found.";
                            _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                            //required track is missing
                        } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
                            _thisObject.$store.dispatch("STOP_PROCESSING");
                            _thisObject.error = true;
                            _thisObject.stage = "Your Camera being used by another App.";
                            _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                            //webcam or mic are already in use
                        } else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {
                            _thisObject.$store.dispatch("STOP_PROCESSING");
                            _thisObject.error = true;
                            _thisObject.stage = "The requested Camera configuration cannot be satisfied by this device.";
                            _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                            //constraints can not be satisfied by avb. devices
                        } else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {
                            _thisObject.$store.dispatch("STOP_PROCESSING");
                            _thisObject.error = true;
                            _thisObject.stage = "Browser permission denied";
                            _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                            //permission denied in browser
                        } else if (err.name == "TypeError" || err.name == "TypeError") {
                            //empty constraints object
                            _thisObject.$store.dispatch("STOP_PROCESSING");
                            _thisObject.error = true;
                            _thisObject.stage = "There is an issue with the constraint.";
                            _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                        } else {
                            //other errors
                            _thisObject.$store.dispatch("STOP_PROCESSING");
                            _thisObject.error = true;
                            _thisObject.error = true;
                            _thisObject.stage = "An error occurred, please try again later.";
                            _thisObject.$store.dispatch("GET_MESSAGES", [_thisObject.stage]);
                        }
                    });
            },
            stopStreaming()
            {
                if(null !== this.objStream)
                {
                    this.cameraView.srcObject = null;
                    this.objStream.getTracks().forEach(function(track) {
                        track.stop();
                    });

                    this.objStream = null;
                    this.isStreaming = false;
                }
            },
            dataURItoBlob( dataURI )
            {
                let byteString = atob( dataURI.split( ',' )[ 1 ] );
                let mimeString = dataURI.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ];

                let buffer	= new ArrayBuffer( byteString.length );
                let data	= new DataView( buffer );

                for( let i = 0; i < byteString.length; i++ ) {
                    data.setUint8( i, byteString.charCodeAt( i ) );
                }

                return new Blob( [ buffer ], { type: mimeString } );
            },
            prepareReload()
            {
                setTimeout(() => {
                    this.$store.dispatch("START_PROCESSING");
                    window.location.reload();
                }, 10000);
                setInterval(() => this.timer--, 1000);
            },
            submitcomplete()
            {
                this.canSubmit = false;
                this.selected_comp = true;
                this.selected_component = 'Summary';
            },
        },
        computed: {
            stageHasContent: function()
            {
                return (typeof this.stage !== "undefined" && this.stage !== null && this.stage.length > 0);
            },
        },
    }
</script>
<style>
    #camera__output, #camera__sensor, #camera__view
    {
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    /*#camera__output, #camera__sensor, #camera__view
    {
      transform: scaleX(-1);
      filter: FlipH;
    }*/
    #trigger
    {
        width: 200px;
        /*background-color: #424242 !important;*/
        /*color: white;*/
        font-size: 16px;
        border-radius: 30px;
        border: none;
        /*padding: 15px 20px;*/
        text-align: center;
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
        position: fixed;
        bottom:30px;
        right: 30px;
    }
</style>