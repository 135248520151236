<template>
    <div class="col s12 m10 offset-m1  l10 offset-l1 vert-offset-top-2">

        <div class="col s12  vert-offset-bottom-1 text-align-center">
            <h5>
                Do you wish to share photographic evidence?
            </h5>
        </div>
        <div class="row">
            <div class=" col s12 vert-offset-top-1  border-top-1 grey-border border-lighten-2 padding-top-1 padding-left-0 padding-right-0">
                <div  class="col s6 text-align-right">
                    <button v-show="hasCamera"
                            class="btn grey darken-2 font-10"
                            @click.prevent="loadCamera"
                    >
                        Yes
                    </button>
                </div>
                <div class="col s6">
                    <button
                            class="btn grey darken-2 font-10"
                            @click.prevent="loadSummary"
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "TakePicture",
        props:['sites','selected_offences'],
        components:{

        },
        data(){
            return{
                selected_comp:false,
                selected_component:"",
            }
        },
        methods:{


            loadCamera: function()
            {

                this.$store.dispatch("SET_SHOW_CAMERA", true);
            },
            loadSummary: function()
            {


                this.$store.dispatch("SET_SHOW_SUMMARY", true);
            },
        },
        computed:{


            nextComponentSelected: function()
            {
                return (typeof this.selected_component !== "undefined" && this.selected_component !== null && this.selected_component.length > 0);
            },
            hasCamera: function()
            {
                if(typeof window.navigator === "undefined" || window.navigator === null || typeof window.navigator.mediaDevices === "undefined" || window.navigator.mediaDevices === null)
                {
                   return false;
                }

                if(typeof window.navigator.mediaDevices.getUserMedia === "undefined" || window.navigator.mediaDevices.getUserMedia === null)
                {
                    return false;
                }

                return true;
            },
        },
    }
</script>

<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }

    .card-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;
    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>